<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">Neues Ticket</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Neues Support Ticket</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-text-field v-model="subject" label="Betreff" />
                    <v-textarea v-model="message" label="Nachricht" />
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="open = false">Abbrechen</v-btn>
                <v-btn @click="createTicket()" color="primary">Erstellen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../apis/xeonserv'

export default {
    data() {
        return {
            open: false,
            subject: '',
            message: '',
        }
    },
    methods: {
        createTicket() {
            xeonserv().post('/support/tickets', {
                subject: this.subject,
                message: this.message
            }).then(res => this.$router.push({ name: 'dashboard-support-ticket', params: { id: res.data.data.id }}))
        }
    }
}
</script>