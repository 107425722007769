<template>
    <div class="px-page mt-5 py-5">
        <datatable path="/support/tickets" :headers="[
            {text: 'ID', value: 'id'},
            {text: 'Status', value: 'state'},
            {text: 'Betreff', value: 'subject'},
            {text: 'Datum', value: 'created_at'},
            {text: 'Aktionen', value: 'actions'}
        ]">
            <template v-slot:actions>
                <NewTicket />
            </template>
            <template v-slot:[`item.state`]="{ item }">
                <TicketState :state="item.state" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn small color="primary" :to="{ name: 'dashboard-support-ticket', params: { id: item.id } }">Ansehen</v-btn>
            </template>
        </datatable>
    </div>
</template>

<script>
import TicketState from '../../components/support/TicketState'
import NewTicket from '../../components/support/NewTicket.vue'

export default {
    components: { TicketState, NewTicket }
}
</script>