var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-page mt-5 py-5"},[_c('datatable',{attrs:{"path":"/support/tickets","headers":[
        {text: 'ID', value: 'id'},
        {text: 'Status', value: 'state'},
        {text: 'Betreff', value: 'subject'},
        {text: 'Datum', value: 'created_at'},
        {text: 'Aktionen', value: 'actions'}
    ]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('NewTicket')]},proxy:true},{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_c('TicketState',{attrs:{"state":item.state}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'dashboard-support-ticket', params: { id: item.id } }}},[_vm._v("Ansehen")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }